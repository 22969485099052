import React from "react";
import Row from "react-bootstrap/Row";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Colors from "../styles/Colors";
import Languages from "../referentials/Languages";

const ImgAsLink = (props) => {
  return (
    <a href={props.link} target="_blank" rel="noreferrer" style={props.logoStyle}>
      <Img fluid={props.childImageSharp.fluid} durationFadeIn={50} />
    </a>
  );
};

export default ({ language }) => {
  const data = useStaticQuery(graphql`
  query {
    logoFacebook: file(relativePath: {eq: "logos/facebook-icon.png"}) {
      childImageSharp {
        fluid (maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoInstagram: file(relativePath: {eq: "logos/instagram-icon.png"}) {
      childImageSharp {
        fluid (maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoGuide: file(relativePath: {eq: "logos/logo_guide.png"}) {
      childImageSharp {
        fluid (maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoTvMoutain: file(relativePath: {eq: "logos/tvmountain-logoblanc.png"}) {
      childImageSharp {
        fluid (maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    footerText: footerYaml {
      footerTextFr
      footerTextEn
    }
  }`);

  const footerText = language === Languages.en ? data.footerText.footerTextEn : data.footerText.footerTextFr;

  const footerStyle = {
    position: "relative",
    bottom: "0",
    width: "100%",
    borderStyle: "double none none none",
    borderWidth: "0.2rem",
    borderColor: Colors.yellow
  }

  const footerTextStyle = {
    margin: "0.25rem"
  }

  const logoStyle = {
    width: "2rem",
    margin: "0.25rem 0.75rem 0.25rem 0.75rem",
  }

  const logoFacebookProps = {
    title: "Facebook",
    childImageSharp: data.logoFacebook.childImageSharp,
    logoStyle: logoStyle,
    link: "https://www.facebook.com/profile.php?id=100088472061309"
  }

  const logoInstagramProps = {
    title: "Instagram",
    childImageSharp: data.logoInstagram.childImageSharp,
    logoStyle: logoStyle,
    link: "https://www.instagram.com/guides_independants_chamonix/"
  }

  const logoGuideProps = {
    title: "UIAGM",
    childImageSharp: data.logoGuide.childImageSharp,
    logoStyle: logoStyle,
    link: "https://www.guides-montagne.org/pages/luiagm"
  }

  const logoTvMountainProps = {
    title: "TV Moutain",
    childImageSharp: data.logoTvMoutain.childImageSharp,
    logoStyle: logoStyle,
    link: "https://www.tvmountain.com/"
  }

  return (
    <footer className='bg-white text-center' style={footerStyle}>
      <Row className='justify-content-center'>
        <h6 style={footerTextStyle}>{footerText}</h6>
        <ImgAsLink {...logoInstagramProps} />
        <ImgAsLink {...logoFacebookProps} />
        <ImgAsLink {...logoTvMountainProps} />
        <ImgAsLink {...logoGuideProps} />
      </Row>
    </footer >
  );
}