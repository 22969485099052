import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import Colors from "../styles/Colors";
import Languages from "../referentials/Languages";

// TODO : on phone, when clicking on burger, change background color of links to pages

const FlagImg = styled(Img)`
max-width: 50px;
margin-left: 10px;
margin-right: 10px;
&:hover {
  cursor: pointer;
}
`;

const FlagIcon = ({ logo, language, logoStyle, handleLanguage }) => {
  const flagIconSource = language === Languages.fr ? logo.englishFlag.childImageSharp.fluid : logo.frenchFlag.childImageSharp.fluid;
  return (
    <div role="button" tabIndex="0" onClick={handleLanguage} onKeyPress={handleLanguage}>
      <FlagImg fluid={flagIconSource} durationFadeIn={100} style={logoStyle} />
    </div>
  );
}

export default (props) => {
  const logo = useStaticQuery(graphql`
  query logoGic {
    logoGic: file(relativePath: {eq: "logos/logo_gic.jpeg"})  {
      childImageSharp {
        fluid (maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    frenchFlag: file(relativePath: {eq: "logos/french-flag-icon.png"})  {
      childImageSharp {
        fluid (maxWidth: 100, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    englishFlag: file(relativePath: {eq: "logos/english-flag-icon.png"})  {
      childImageSharp {
        fluid (maxWidth: 100, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`);

  const logoStyle = {
    width: "4rem"
  };

  const navbarStyle = {
    padding: "0px",
    borderStyle: "none none double",
    borderWidth: "0.2rem",
    borderColor: Colors.blue
  };

  const gicStyle = {
    padding: "0"
  }

  return (
    <Navbar collapseOnSelect expand="xl" className="bg-white" sticky="top" style={navbarStyle} onSelect={(eventKey) => props.handleSelectPage(eventKey)}>
      <Navbar.Brand href="/" style={gicStyle}>
        <Img fluid={logo.logoGic.childImageSharp.fluid} durationFadeIn={100} style={logoStyle} />
      </Navbar.Brand>
      <a href="/">
        <h1>G.I.C</h1>
      </a>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto" activeKey={props.pathname}>
          {props.siteMetadata.menuLinks.map((link, index) => (
            <Nav.Link key={link.name + index} eventKey={link.link}><h5>{link.name}</h5></Nav.Link>
          ))}
        </Nav>
        <FlagIcon logo={logo} language={props.language} logoStyle={logoStyle} handleLanguage={props.handleLanguage} />
      </Navbar.Collapse>
    </Navbar >
  );
}
