import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql, navigate } from "gatsby";
import Header from "./Header";
import Footer from "./Footer";
import Languages from "../referentials/Languages";


// TODO : sur certains écrans, le footer n'est pas en bas de page sur la page principale

export default ({ children, location }) => {
  const data = useStaticQuery(graphql`
  query SiteTitleQuery {
      site {
        siteMetadata {
          fr {
            title
            subtitle
            description
            menuLinks {
              name
              link
            }
          }
          en {
            title
            subtitle
            description
            menuLinks {
              name
              link
            }
          }
        }
      }
    }
  `);

  const [pathname, setPathname] = useState(location.pathname);
  function handleSelectPage(eventKey) {
    setPathname(eventKey);
    navigate(eventKey);
  };


  const [language, setLanguage] = useState(Languages.en);
  function handleLanguage() {
    setLanguage(Languages.getLanguageToSwitchTo(language));
  };

  let siteMetadata = null;
  if (language === Languages.en) {
    siteMetadata = data.site.siteMetadata.en;
  } else {
    siteMetadata = data.site.siteMetadata.fr;
  }

  const contentWrapStyle = {
    position: "relative",
    minHeight: "100vh",
    overflow: "hidden",
    width: "100vw",
  };

  const headerProps = {
    siteMetadata: siteMetadata,
    pathname: pathname,
    handleSelectPage: handleSelectPage,
    handleLanguage: handleLanguage,
    language: language
  };

  return (
    <React.Fragment>
      <Helmet>
        <html lang={language} />
        <meta charSet="utf-8" />
        <title>{siteMetadata.title}</title>
        <meta name="description" content={siteMetadata.description} />
      </Helmet>
      <Header {...headerProps} />
      <div id="content-wrap" style={contentWrapStyle} >
        {React.Children.map(children, child => {
          return React.cloneElement(child, {
            siteMetadata: siteMetadata,
            handleSelectPage: handleSelectPage,
            pathname: pathname,
            language: language
          });
        })
        }
      </div>
      <Footer language={language} />
    </React.Fragment >
  )
}
